import {connect} from "react-redux";
import React,{Component} from "react";
import AddUserFormUI from "./AddUserFormUI";
import {HTTPCode, PS_FETCH_USER_MANAGE_LIST, PS_SET_EDITING_USER} from "../../../constant";
import {message} from "antd";
import PubSub from "pubsub-js"
import Query from "../../../api/query";

class AddUserForm extends Component {

  componentDidMount() {
    PubSub.subscribe(PS_SET_EDITING_USER,(msg,user)=>{
      console.log(user);
      this.formRef.current.setFieldsValue({
        name:user.name,
        username:user.username,
        wxId:user.wxId,
      })
    })
  }

  formRef = React.createRef();

  clear=()=>{
    this.formRef.current.setFieldsValue({
      name:'',
      username:'',
      wxId:'',
    })
  }

  addUserAccount = (value) => {
    Query.addUserAccount(value).then(res => {
      const response = res.data;
      if (response.metaInfo.status === HTTPCode.ok) {
        message.success("注册成功", 5);
        this.formRef.current.resetFields();
        this.props.handleAddUserModalClose();
        this.clear();
      } else {
        message.error(response.metaInfo.msg, 10);
      }
      PubSub.publish(PS_FETCH_USER_MANAGE_LIST);
    }).catch(e => {
      console.log(e);
      message.error("注册出错了！" + e, 10);
      PubSub.publish(PS_FETCH_USER_MANAGE_LIST);
    })
  }

  updateAccount = (value) => {
    Query.updateUserAccountInfo(value).then(res => {
      const response = res.data;
      if (response.metaInfo.status === HTTPCode.ok) {
        message.success("修改成功", 5);
        this.formRef.current.resetFields();
        this.props.handleAddUserModalClose();
        this.clear();
      } else {
        message.error(response.metaInfo.msg, 10);
      }
      PubSub.publish(PS_FETCH_USER_MANAGE_LIST);
    }).catch(e => {
      console.log(e);
      message.error("修改出错了！" + e, 10);
      PubSub.publish(PS_FETCH_USER_MANAGE_LIST);
    })
  }

  handleFinish=(value)=>{
    if(this.props.createOrUpdate==="create"){
      this.addUserAccount(value);
    }else {
      this.updateAccount(value);
    }
  }

  render() {
    return (
      <div>
        <AddUserFormUI handleFinish={this.handleFinish} formRef={this.formRef} createOrUpdate={this.props.createOrUpdate}/>
      </div>
    )
  }
}

const stateToProps = (state) => {
  return {};
};

const dispatchToProps = (dispatch) => {
  return {};
};

export default connect(stateToProps, dispatchToProps)(AddUserForm);
