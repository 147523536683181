import {connect} from "react-redux";
import {Component} from "react";
import UserManagePageUI from "./UserManagePageUI"
import PubSub from "pubsub-js"
import {PS_SET_EDITING_USER} from "../../constant";

class UserManagePage extends Component {
  componentDidMount() {
  }

  state = {
    isModalVisible: false,
    createOrUpdate:'create',
    updateUser:'',
  };

  handleAddUserButtonClick = () => {
    this.setState({isModalVisible: true,createOrUpdate:'create'});
  }

  handleAddUserModalClose = ()=>{
    this.setState({isModalVisible:false})
  }

  setUpdateUserAndUpdate=(updateUser)=>{
    this.setState({isModalVisible: true,createOrUpdate:'update'});
    this.setState({updateUser});
    PubSub.publish(PS_SET_EDITING_USER,updateUser);
  }

  render() {
    return (
      <UserManagePageUI
        isModalVisible={this.state.isModalVisible}
        handleAddUserButtonClick={this.handleAddUserButtonClick}
        handleAddUserModalClose={this.handleAddUserModalClose}
        setUpdateUserAndUpdate={this.setUpdateUserAndUpdate}
        createOrUpdate={this.state.createOrUpdate}
      />
    )
  }
}

const stateToProps = (state) => {
  return {};
};

const dispatchToProps = (dispatch) => {
  return {};
};

export default connect(stateToProps, dispatchToProps)(UserManagePage);
